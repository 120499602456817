<section class="app-winn">
  <div class="app-winn__con container">
    <h2 class="app-title app-winn__con--h2">Lista zwycięzców loterii Foxy</h2>

    @if (isMobile) {
      <div class="app-winn__con__mob">
        <img
          ngSrc="assets/images/winners/winners-elements-mob.webp"
          alt="winners"
          width="781"
          height="504"
          priority="false"
          class="w-100 h-auto"
          [appAnimate]="'fadeIn'"
          [speed]="'normal'"
          [aos]="0.3"
          [once]="true"
          [delay]="'100'"
        />
        <div class="app-winn__con__mob__wr">
          @for (win of winners(); track $index) {
            <p class="app-winn__con__mob__wr--week">{{ win.week }} TYDZIEŃ</p>

            <p class="app-winn__con__mob__wr--date">
              {{ win.weekData[win.weekData.length - 1].date }} -
              {{ win.weekData[0].date }}
            </p>

            <table>
              <thead>
                <tr>
                  <th>Nagrody</th>
                  <th colspan="2">
                    Imię laureata <br />
                    i trzy ostatnie cyfry nr telefonu
                  </th>
                </tr>
              </thead>
              <tbody>
                @for (w of win.weekData; track $index) {
                  <tr>
                    <td colspan="3" class="fw-bold py-1">
                      {{ w.date }}
                    </td>
                  </tr>
                  @for (a of w.dayData; track i; let i = $index) {
                    <tr
                      [ngClass]="{
                        last: i === w.dayData.length - 1,
                        'main-rew': a.type === 'main',
                        'second-rew': a.type === 'second'
                      }"
                    >
                      <td>
                        {{ a.reward }}
                      </td>
                      <td>
                        {{ a.firstName }}
                      </td>
                      <td>
                        {{ a.threeLastPhoneNumbers }}
                      </td>
                    </tr>
                  }
                }
              </tbody>
            </table>
          }
        </div>
      </div>
    } @else {
      <div class="app-winn__con__des">
        <img
          ngSrc="assets/images/winners/winners-elements.webp"
          alt="winners"
          width="2732"
          height="1529"
          priority="false"
          class="app-winn__con__des--img"
          [appAnimate]="'fadeIn'"
          [speed]="'normal'"
          [aos]="0.3"
          [once]="true"
          [delay]="'100'"
        />
        <div class="app-winn__con__des__pro">
          <div class="app-winn__con__des__pro__wr head">
            <table>
              <thead>
                <tr>
                  <th><b>Etapy</b></th>
                  <th><b>Nagrody</b></th>
                  <th colspan="2">
                    <b>Imię laureata</b> <br />
                    <span class="fw-medium"
                      >i trzy ostatnie cyfry nr telefonu</span
                    >
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="app-winn__con__des__pro__wr body">
            <table>
              <tbody>
                @for (win of winners(); track $index) {
                  <tr class="week">
                    <td colspan="4">
                      <b
                        >{{
                          win.week === '18'
                            ? 'LOSOWANIE DODATKOWE'
                            : win.week + ' TYDZIEŃ'
                        }}
                      </b>
                    </td>
                  </tr>
                  @for (w of win.weekData; track $index) {
                    <tr
                      class="with-date"
                      [ngClass]="{
                        'main-rew': w.dayData[0].type === 'main',
                        'second-rew': w.dayData[0].type === 'second'
                      }"
                    >
                      <td [rowSpan]="w.dayData.length">{{ w.date }}</td>
                      <td>
                        {{ w.dayData[0].reward }}
                      </td>
                      <td>
                        {{ w.dayData[0].firstName }}
                      </td>
                      <td>
                        {{ w.dayData[0].threeLastPhoneNumbers }}
                      </td>
                    </tr>
                    @for (a of w.dayData; track i; let i = $index) {
                      @if (i !== 0) {
                        <tr
                          [ngClass]="{
                            last: i === w.dayData.length - 1,
                            'main-rew': a.type === 'main',
                            'second-rew': a.type === 'second'
                          }"
                        >
                          <td>
                            {{ a.reward }}
                          </td>
                          <td>
                            {{ a.firstName }}
                          </td>
                          <td>
                            {{ a.threeLastPhoneNumbers }}
                          </td>
                        </tr>
                      }
                    }
                  }
                } @empty {
                  <tr>
                    <td colspan="4">Brak danych</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    }
  </div>
</section>
