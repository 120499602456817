import { NgClass } from '@angular/common';
import { Component, output } from '@angular/core';
import { SectionType } from '@app/models/section-type';
import { GtmClickDirective } from '@app/directives/gtm-click.directive';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [NgClass, GtmClickDirective],
})
export class HeaderComponent {
  public navbarExpanded = false;
  public readonly SectionType = SectionType;

  public readonly navigationClick = output<SectionType>();

  public onClick(sectionType: SectionType): void {
    this.onNavbarToggle();
    this.navigationClick.emit(sectionType);
  }

  public onNavbarToggle(): void {
    this.navbarExpanded = !this.navbarExpanded;
  }

  protected readonly window = window;
}
